body {
    background: #fafafa;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;

}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc;
    vertical-align: bottom;
}

.navbar-brand-name {
    color: #ccc;
    vertical-align: bottom;
}

/* ==========================================================================
Navbar
========================================================================== */
.navbar {
    background: rgb(55,56,57);
    color: white;
}

/* ==========================================================================
Menus
========================================================================== */
.navbar > div > div > ul > li > a {
    color: white !important;
}
.navbar > div > div > ul > li > a:hover {
    background: rgb(46,162,224) !important;
}
/* ==========================================================================
Active menu
========================================================================== */
.navbar > div > div > ul > .active > a {
    background: rgb(42,125,170) !important;
}

.navbar > div > div > ul > .open > a {
    background: rgb(42,125,170) !important;
}
/* ==========================================================================
Background for body
========================================================================== */
body{
    background: url("../images/background_light.png") no-repeat center top;
}

/* ==========================================================================
Burger menu
========================================================================== */
.navbar-toggle{
    background: rgb(55,56,57) !important;
}
.icon-bar{
    background: white !important;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 0px 15px;
}

.logo .logo-img {
    height: 50px;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

.abc {
    display: inline-block;
    width: 300px;
    height: 200px;
    background: url("../images/ABC_QualityMatch_tunnus_cmyk_R.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
    cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {

    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
content width tweak
========================================================================== */


@media (min-width: 768px) {
    .container {
        width: auto;
    }
}
@media (min-width: 992px) {
    .container {
        width: auto;
    }
}
@media (min-width: 1200px) {
    .container {
        width: auto;
    }
}


@media screen and (min-width:1600px){
    .container{
        width: 1580px
    }
}

/* ==========================================================================
burger tweak
========================================================================== */

@media (min-width: 768px) and (max-width: 1200px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .navbar-nav .open .dropdown-menu > li > a {
        line-height: 20px;
    }
    .navbar-nav .open .dropdown-menu > li > a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 5px 15px 5px 25px;
    }
    .dropdown-menu > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
    }
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        /*margin: 7.5px -15px;*/
        margin: 7.5px -15px 7.5px -15px
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-text {
        float: none;
        margin: 15px 0;
    }
    /* since 3.1.0 */
    .navbar-collapse.collapse.in {
        display: block!important;
    }
    .collapsing {
        overflow: hidden!important;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #555;
        background-color: #e7e7e7;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #aaa;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
        color: #000;
    }

    .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
        margin-right: 0;
        margin-left:  0;
    }
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
        color: #555;
        background-color: #e7e7e7;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #aaa;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
        color: #000;
    }

    .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
        margin-right: 0;
        margin-left: 0;
    }
    .well{
        padding:0.3em;
    }
    .panel-body {
        padding: 0px;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}

/* ==========================================================================
Dashboard styles
========================================================================== */
.dashboard-widget.panel-default {
    border-color: #fff;
    color: rgb(26,87,120);
}

.dashboard-widget.panel-default > .panel-heading, .dashboard-widget.panel-default > .panel-footer {
    background-color: rgb(174,218,242);
    color: rgb(26,87,120);
}

.dashboard-widget.panel-default > .panel-body {
    background-color: rgb(234,234,234);
}

.dashboard-widget-footer {
    min-height: 53px;
}

.modal-wrapper {
    padding: 10px;
}

/* ==========================================================================
Bootstrap table tweaking to ui colors
========================================================================== */
.table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    border-top: 1px solid white;
    color: rgb(26,87,120);
    vertical-align: top;
}

.table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid white;
    color: rgb(26,87,120);
    vertical-align: top;
}

.table {
    background-color: rgb(234,234,234);
}

/* ==========================================================================
QuestionnaireAnswer
========================================================================== */

.questionnaire .anomaly-type-select{
    padding-left:2em;
}

.questionnaire-answer.questionnaire-form {

}

.questionnaire-answer .question-row {
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
}

.questionnaire-answer .question-row .question-meta-info > div  {
    margin-bottom: 0.5em;
    max-width: 50em;
}

.questionnaire-answer .half-column {
    width:50%;
    float:left;
}

.questionnaire-answer .half-clear-column {
    width:50%;
    clear:both;
}

.questionnaire-answer .clear-column {
    clear:both;
}

.questionnaire-answer .multi-question-radio-group .btn-primary.active {
    border-bottom: 0.5em solid black;
}

.questionnaire-form .description-view {
    white-space: pre-wrap;
}

/* ==========================================================================
uib-dropdown table fix
========================================================================== */

.uib-dropdown-friendly {

    overflow-y: visible;
}

/* ==========================================================================
uib-dropdown table fix
========================================================================== */

.questionnaire-answer .ticket-material-download-link-list{
    list-style: none;
    padding:0em;
}
.questionnaire-answer .ticket-material-download-link-list li{
    padding-bottom:0.4em;
}

.questionnaire-answer .table-ticketmaterial td:last-child{
    padding-left: 2em;
    width: 1em;
    white-space: nowrap;
}

.questionnaire-answer .material-upload-alert > div{
    display: inline-block;
}

.questionnaire-answer .material-upload-alert > div:first-child{
    margin-right: 1em;
}


.questionnaire-answer .upload-spinner {
    border: 0.3em solid #f3f3f3;/* Light grey */
    border-top: 0.3em solid #3498db; /* #286090; */
    border-radius: 50%;
    width: 2em;
    height: 2em;
    animation: ticket_upload_spinner_spin 0.8s linear infinite;
}

@keyframes ticket_upload_spinner_spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.questionnaire-answer .table-ticketmaterial-view td:last-child{
    padding-left: 4em;
    width: 1em;
    white-space: nowrap;
}
.questionnaire-answer .table-ticketmaterial-view{
    width: auto !important;
    background: none !important;
}

.questionnaire-answer a.materialLink{
    max-width: 30em;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/*
 //QMYP-103 answerAttachmentteihin liittyävät tyylit tänne namespacetettuna esim.
 .questionnaire-answer-attachment .attachement-table{
 }


 */

/* ==========================================================================
uib-dropdown table fix
========================================================================== */

.documentfile .documentfile-download-link-list{
    list-style: none;
    padding:0em;
}
.documentfile .documentfile-download-link-list li{
    padding-bottom:0.4em;
}

.documentfile .table-documentfile td:last-child{
    padding-left: 2em;
    width: 1em;
    white-space: nowrap;
}

.documentfile .documentfile-upload-alert > div{
    display: inline-block;
}

.documentfile .documentfile-alert > div:first-child{
    margin-right: 1em;
}


.documentfile .upload-spinner {
    border: 0.3em solid #f3f3f3;/* Light grey */
    border-top: 0.3em solid #3498db; /* #286090; */
    border-radius: 50%;
    width: 2em;
    height: 2em;
    animation: document_file_upload_spinner_spin 0.8s linear infinite;
}

@keyframes document_file_upload_spinner_spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.documentfile .table-documentfile-view td:last-child{
    padding-left: 4em;
    width: 1em;
    white-space: nowrap;
}
.documentfile .table-documentfile-view{
    width: auto !important;
    background: none !important;
}

.documentfile a.documentfilelLink{
    max-width: 30em;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* ==========================================================================
required field asterisk
========================================================================== */

.form-group label.control-label.required:after {
    content: " *";
    color: red;
}

/* ==========================================================================
fix for stupid cut off of long labels
========================================================================== */
.dl-horizontal dt{
    white-space: normal;
}

/* jhipster-needle-css-add-main JHipster will add new css style */

.information-event-select{
    max-width: 20em;
}

.information-event-select input{
}

.information-event-select .search,
.information-event-select .spinner{

}

.information-event-select .form-control-feedback{
    z-index: 4;
}

.information-event-select .information-item .meta{
    color: #9c9c9c;
}
.information-event-select .information-item{
    border-bottom: thin solid #e5e5e5;
    padding-bottom: 0.8em;
}



.anomaly-event-select{
    max-width: 20em;
}

.anomaly-event-select input{
}

.anomaly-event-select .search,
.anomaly-event-select .spinner{

}

.anomaly-event-select .form-control-feedback{
    z-index: 4;
}

.anomaly-event-select .anomaly-item .meta{
    color: #9c9c9c;
}
.anomaly-event-select .anomaly-item{
    border-bottom: thin solid #e5e5e5;
    padding-bottom: 0.8em;
}



.container{
    padding-right: 0;
    padding-left: 0;
    max-width: 1580px
}

span.long-text{
    max-width: 30em;
    min-width: 10em;
    display: inline-block;
    white-space: pre-line;
}

.table-responsive > .table > thead > tr > th,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > td {
    white-space: normal !important;
  }



.table-ticketgmaterial-view td:last-child{
    padding-left: 4em;
    width: 1em;
    white-space: nowrap;
}
.table-ticketmaterial-view{
    width: auto !important;
    background: none !important;
}


.listing-filters{
    margin-bottom: 1em;
}

.listing-filters .seachField{
    white-space: nowrap;
    margin-bottom: 0.4em;
}
.listing-filters .seachField input[type=text]{
    width: 18em;
    display: inline;
}

.listing-filters .date-select-container{
    white-space: nowrap;
}

.listing-filters .timespan-limit .date-select-container > input[type=text] {
    width: 10em;
}

.listing-filters .timespan-limit .date-select-container{
    display: inline-block;
    margin:0.2em;
}

.listing-filters .timespan-limit .date-select-container >*{
    display: inline;
}
.listing-filters .select-container{
    max-width: 20em;
}

.listing-filters select option.placeholder{
    color:lightgray;
}

.listing-filters > fieldset > div{
    display: inline-block;
    margin-bottom: 0.5em;
}

.listing-filters > fieldset{
    display: inline-block;
    margin-bottom: 0.5em;
}

.listing-filters > fieldset {
    display: inline-block;
}

.listing-filters > fieldset{
    margin-right: 1em;
}

fieldset{
    border-top:thin solid rgba(26, 87, 120, 0.13);
    padding-left:0.5em;
    padding-right:0.5em;
}

fieldset legend{
    font-size: 1em;
    border:none;
    margin-bottom: 0em;
    width: auto;
    color: rgb(26,87,120);
}

hr {
    border-top: 1px solid #33333345;
}

/*for inputGroup containing date select*/
.dateInputGroup {
    max-width: 14em;
}

.highlightedMatch{
    color: #0f0f0f;
    background-color: yellow;
}


.welcome .abc-logo{
    display: inline-block;
    margin-bottom: 1em;
    vertical-align: top;    
}

.welcome .login-form{
    display: inline-block;
    max-width: 20em;    
}

.welcome .login-form input[type=text]{
    width: 20em;
}

.welcome .alert{
    margin-top: 1em;
}

.welcome .welcome-content{
    max-width: 50em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    margin-bottom: 4em;    
}


.welcome .message{
    margin-top: 3em;
    text-align: center;
    font-weight: bold;
    font-size:larger;
}



/*wrapper for all training related*/
.training {
    
}

.training .training-material-download-link-list{
    list-style: none;
    padding:0em;  
}
.training .training-material-download-link-list li{
    padding-bottom:0.4em;
}

.training .valid-for-years-input,
.training .training-points-input{
    width:auto;
    max-width: 5em;
}

.training .table-signup-edit td:first-child{
    width: 1em;
    white-space: nowrap;
}

.training .table-signup-edit td:last-child{
    width: 1em;
    white-space: nowrap;
    padding-left: 2em;
}
.training .table-signup-edit td textArea{
    width: 100%;
}

.training .table-checklist-edit td:first-child{
    width: 1em;
    white-space: nowrap;
}

.training .table-checklist-edit td:nth-child(3){
    width: 1em;
    white-space: nowrap;
    padding-left: 1em;
    padding-right: 2em;
}

.training .table-checklist-edit td:last-child{
    width: 1em;
    white-space: nowrap;
}
.training .table-checklist-edit td textArea{
    width: 100%;
}
.training .table-checklist-edit td:nth-last-child(2) input{
    max-width: 25em;
    margin-top:0.2em;
}

.training .table-trainingmaterial td:last-child{
    padding-left: 2em;
    width: 1em;
    white-space: nowrap;
}

.training .material-upload-alert > div{
    display: inline-block;
}

.training .material-upload-alert > div:first-child{
    margin-right: 1em;
}


.training .upload-spinner {
    border: 0.3em solid #f3f3f3;/* Light grey */
    border-top: 0.3em solid #3498db; /* #286090; */
    border-radius: 50%;
    width: 2em;
    height: 2em;
    animation: training_upload_spinner_spin 0.8s linear infinite;
}

@keyframes training_upload_spinner_spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.training .table-trainingmaterial-view td:last-child{
    padding-left: 4em;
    width: 1em;
    white-space: nowrap;
}
.training .table-trainingmaterial-view{
    width: auto !important;
    background: none !important;
}

.training a.materialLink{
    max-width: 30em;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}



.training .teaching-time-spend,
.training .exam-pass-percent{
    white-space: nowrap;
    display: block;
}

.training .exam-pass-percent input{
    width: 7em;
    display: inline-block;
}

.training .teaching-time-spend input{
    width: 7em;
    display: inline-block;
}

.training .exam-questions-list li span{
    white-space: pre-line;
    display: block;
    max-width: 40em;
    vertical-align: top;
}


.training .checklist-list li,
.training .exam-questions-list li{
    padding-bottom: 1em;
}

.training .checklist-list li input{
    margin-right: 0.5em;
}
.training .checklist-list li label{
    font-weight: normal;
}
.training .checklist-list li span.item-content{
    display: inline-block;
    vertical-align: top;
}

.training .checklist-list li span.item-text{
    white-space: pre-line;
    display: block;
    max-width: 40em;
    vertical-align: top;
}

.training .checklist-list li span.time-estimate{
    display: block;
    margin-top:0.2em;  
}



.training .table-signup-view td{
    width: 1em;
    white-space: nowrap;
}

.training .table-signup-view td:last-child{
    width: auto;
}

.training .table-signup-edit tbody  td{
    width: 1em;
    white-space: nowrap;
    padding-right: 3em;
}

.training .table-signup-edit tbody td:last-child{
    width: auto;
    padding-right: 1em;
}

.training .table-signup-edit tfoot select{
    display: inline-block;
    max-width: 20em;
} 

.training .end-training-event-button {
    margin-left: 2em !important; 
}

.training .table-exam-questions-use{
    width: auto;
}

.training .table-exam-questions-use tbody td:first-child{
    width: 1em;
}

.training .table-exam-questions-use tbody td:nth-child(2){
    white-space: pre-line;
    max-width: 40em;
}

.training .table-exam-questions-use tbody td input{
    margin-right:0.5em;
}

.training .training-singup-pledge-label{
    font-weight: normal;
    max-width: 40em;
    vertical-align: top;
    padding-left: 0.5em;
}

.training .examOrSigningAlert table{
    margin-top: 1em;
}

.training .examOrSigningAlert table td{
    padding-left: 1em;
    padding-bottom: 0.5;
}
.training .examOrSigningAlert table button{
    margin-bottom: 0.5em;
}

.training .duration {
    max-width: 10em;
    white-space: nowrap;
}

.training .duration > input {
    display:inline-block;
    text-align: right;
}

.training .traininglabel {
    font-weight: normal;
}

.training .expected-trainings-view {
    max-width: 40em;
}


.user-management .dateLimitedActivityDates{
    display: inline-block;
    margin-left: 1em;
    margin-bottom: 0.6em;
}

.user-management .activityTypeList li{
    list-style: none;
}

.user-management .activity-time_limit{
    display: inline-flex;
    white-space: nowrap;
    margin-bottom: 0.3em;
}

.user-management .activity-time_limit input[type=text]{
    width: auto;
}

.user-management .date-limited-activity-timespan-view {
    font-size: smaller;
}



.user-management .listing-filters .user-activity-status{
    width: auto;
    display: inline;
}


.user-management .listing-filters {
    margin-bottom: 1em;
}

.user-management .authority-list{
    list-style: none;
}



.root-cause-analysis{


}

.root-cause-analysis .table-cause-edit{

}

.root-cause-analysis .table-cause-edit td:first-child{
    width: 1em;
    white-space: nowrap;
}

.root-cause-analysis .table-cause-edit td:nth-child(2){
    width: auto;
}

.root-cause-analysis .table-cause-edit td:last-child{
    width: 1em;
    white-space: nowrap;
}
.root-cause-analysis .table-cause-edit td textArea{
    width: 100%;
}

.root-cause-analysis .table-factors-select td:last-child{
    width: 1em;
    white-space: nowrap;
}


.root-cause-analysis .cause-list{
    list-style: none;
    padding: 0em;
}
.root-cause-analysis .cause-list li{
    padding-bottom: 1em;
}

.root-cause-analysis .cause-list li input{
    margin-right: 0.5em;
}
.root-cause-analysis .cause-list li label{
    font-weight: normal;
}
.root-cause-analysis .cause-list li span.item-content{
    display: inline-block;
    vertical-align: top;
}

.root-cause-analysis .cause-list li span.item-text{
    white-space: pre-line;
    display: inline-block;
    max-width: 40em;
    vertical-align: top;
}
.root-cause-analysis .cause-list li span.item-label{
    font-weight: bold;
    margin-right: 0.7em;
}

.root-cause-analysis .factor-list{
    list-style: none;
    padding: 0em;
}
.root-cause-analysis .factor-list li{
    margin-bottom: 0.3em;
}

.root-cause-analysis .indexTable .long-text{
    max-width: 15em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.root-cause-analysis .indexTableDropdownCutoffFix{
    margin-bottom: 5em;
}

.root-cause-analysis .long-text-detail{
    display: inline-block;
    max-width: 40em;
    white-space: pre-line;
}

.root-cause-analysis .correctiveActionList{
    list-style: none;
    padding: 0em;
}
.root-cause-analysis .correctiveActionList li{
    margin-bottom: 0.5em;
}


.information .issue-significance-list{
    list-style: none;
    display: table;
}
.information .issue-significance-list label{
    font-weight: normal;
}
.information .issue-significance-list .assesment-done{
    margin-top: 0.5em;
    border-top: thin solid #e5e5e5;
    padding-top: 0.5em;
}

.information .analysis-list{
    list-style: none;
    padding: 0em;
}

.information .secondary-section{
    margin: 10px;
    margin-bottom: 40px;
    margin-top: 40px;
}
.information .secondary-section .panel-heading{
    font-size: larger;
    white-space: nowrap;
}

.information .dropdown-menu label{
    font-weight: normal;
    cursor: pointer;
    width: 100%;

}

.information .informationTypesFilter{
    position:relative;
    top: 1.6em;
}



.anomaly-event .pdf-download-link{

}

.anomaly-event .to-root-cause-analysis-button{
    margin-right:3em;
}



.reporting table.statistics .sub-row td:first-child{
    padding-left:2em;
    font-size: 12px;
}

.reporting table.statistics .sub-row:last-child td{
    padding-bottom: 1.2em;
}

.reporting table.statistics td.count{
    text-align: right;
    padding-right:1em;
}



.user-group .users-view,
.user-group .authorities-view,
.user-group .expected-trainings-view {
    max-width: 40em;

}

.user-group .expected-trainings-edit {
    font-weight: normal;
}



.questionnaire .section-header{
    white-space: nowrap;
    margin-bottom: 2em;
}
.questionnaire .section-header .move-up-down-buttons{
    margin-left:1em;
    margin-right:1em;
}

.questionnaire .section-header input{
    display: inline-block;
    width: 100%;
    max-width: 40em;
}

.questionnaire .section-wrapper{
    border-bottom: 1px solid #33333345;
    margin-bottom: 1em;
}

.questionnaire .new-from-tempate-button{
    margin-right:5em;
}



.questionnaireAnswerQuestion{
    margin-bottom: 1em;
}


.anomaly-category-select{
    max-width:20em;
    display: inline;
    margin-left: 1em;
}
.anomaly-category-div{
    margin-left:1em;
    margin-top:1em;
}



.document table.treeTable tr.folder {

}

.document table.treeTable  .folderName{
    font-weight: bold;
}

.document table.treeTable tbody[data-nestingLevel="0"] .folderName{
    font-weight: bold;
    font-size:larger;
}

.document table.treeTable tbody[data-nestingLevel="1"] .folderName{

}

.document table.treeTable .fileName .btn-link{
    padding:0;
}


.document .versioningCreateEditButton{
    margin-left: 13em;
}

.document .edit-form .tab-content{
    padding-top: 1em;
}

.document .creation-motives{
    white-space: pre-wrap;
    max-width: 40em;
}

.document .folder-select-foldername{
    padding-left: 1em;
    padding-right: 1em;
}

.document .deleted{
    text-decoration: line-through;
    color: red;
}

.document .fsItemName{
    white-space: normal;
    text-align: left !important;
    max-width: 40em;
    padding-right: 3em;
    display: inline-block;
    vertical-align: top;
}

.document .notable-files-table > tbody > tr:last-child > td{
    padding-bottom: 1.5em;
}

.document.document-edit-dialog .tab-pane{
    padding-top: 1em;
}

.company .logo {
    width: 20%;
    height: auto;
    margin-bottom: 5px;
}

.company .logoDiv {
    margin-bottom: 15px;

}

#deleteLogoLabel {
    margin-left: 3px;
}

.products #includeArchivedDiv{
    margin-top:10px;
}


.questionnaireAnswer .questionnaire-menu {
    padding: 20px;
    position:absolute;
    width: 15vw;
    max-width: 30em;

}
.questionnaireAnswer .questionnaire-section{
    font-weight: bold;
    padding: 0px;
    margin:0px;
}

.questionnaireAnswer #questionnaire-dropdown{
    position:sticky;
    position:-webkit-sticky;
    float:right;
    top:50px;
    z-index:15;
}

.questionnaireAnswer  .questions {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.questionnaireAnswer .btn{
    white-space: normal;
}


.question-integer-input.invalid-answer,
.question-decimal-input.invalid-answer{
    border-color: red;
    color: red;
}



